<template>
  <div
    v-if="show"
    class="c-circle-loading"
  >
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line
  name: 'CircleLoading',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
<style lang="less" scoped>
@keyframes circleLoadinRotate{
  to{
      transform: rotate(360deg); 
  }
}
.c-circle-loading {
  width: 16px;
  height: 16px;
  background: #767676;
  -webkit-mask: 
  radial-gradient( closest-side circle, #767676 99%, transparent 100%) center top/25% 25% no-repeat,
  radial-gradient( closest-side circle, transparent 49%, #767676 50% 99%, transparent 100%),
  conic-gradient(transparent 10%, #767676 90%);
  -webkit-mask-composite: source-over, source-in;
  animation: circleLoadinRotate 1s linear infinite;
  mask-composite: intersect;
}

</style>
