<template>
  <div class="right-config-dialog">
    <!-- 背景图 -->
    <img
      class="right-bg-img"
      :src="cccRightConfigImgUrl"
    />
    <div class="j-download-warp">
      <!-- 二维码 -->
      <div class="qr-code code">
        <img class="j-verlok-lazy lazyload" />
      </div>

      <!-- 下载链接 -->
      <div class="link-list">
        <a
          class="android-a j-app-info j-new-app-info-android"
          data-type="Android"
          target="_blank"
        >
          <img
            class="google-play-img"
            :src="`${PUBLIC_CDN}/she_dist/images/shein-right-config-google-play-895935112d.png`"
            alt=""
          />
        </a>

        <a
          class="j-app-info j-new-app-info"
          data-type="IOS"
          target="_blank"
        >
          <img
            class="app-store-img"
            :src="`${PUBLIC_CDN}/she_dist/images/shein-right-config-app-store-a91994bda5.png`"
            alt=""
          />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
const { PUBLIC_CDN } = gbCommonInfo

export default {
  name: 'RightConfigDialog',
  props: {
    cccRightConfigImgUrl: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      PUBLIC_CDN,
    }
  },
}
</script>

<style lang="less">
.right-config-dialog {
  .j-verlok-lazy {  /* stylelint-disable-line */
    width: 88px;
    height: 88px;
    position: relative;
    top: -132px;
    left: 32px;
  }
  .link-list {
    position: relative;
    img {
      width: 107px;
      height: 29px;
      position: absolute; 
      cursor: pointer;
    }
    .google-play-img {
      top: -210px;
      left: 133px;
    }
    .app-store-img {
      top: -170px;
      left: 133px;
    }
  }
}
</style>
