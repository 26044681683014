const GB_analysis_obj_top = {
  _clickStatus: {},
  clickLoading(type) {
    if (this._clickStatus[type]) {
      return true
    } else {
      this._clickStatus[type] = true
      setTimeout(() => {
        this._clickStatus[type] = false
      }, 300)
      return false
    }
  },
  bindEvent() {
    document.addEventListener('click', (event) => {
      if (event.target.matches('.j-uptt')) {
        sa('send', {
          activity_name: 'click_back_to_top'
        })
      }
    })
  }
}

document.addEventListener('DOMContentLoaded', () => {
  GB_analysis_obj_top.bindEvent()
})
