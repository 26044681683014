function popupQuestionnaire() {
  sa('send', {
    activity_name: 'expose_popup_questionnaire'
  })
}
function clickPopupQuestionnaire() {
  sa('send', {
    activity_name: 'click_popup_questionnaire'
  }, { 
    beacon: 1 
  })
}
export {
  popupQuestionnaire,
  clickPopupQuestionnaire
}
