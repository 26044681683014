const { PUBLIC_CDN } = gbCommonInfo
export default {
  imgUrls: {
    ar: `${PUBLIC_CDN}/she_dist/images/download_qrcode/Homepagear504-1eddf3a7bf.png`,
    asia: `${PUBLIC_CDN}/she_dist/images/download_qrcode/Homepageasia504-e04945292d.png`,
    au: `${PUBLIC_CDN}/she_dist/images/download_qrcode/Homepageau504-0a48439e74.png`,
    br: `${PUBLIC_CDN}/she_dist/images/download_qrcode/Homepagebr504-2de126fe9f.png`,
    ca: `${PUBLIC_CDN}/she_dist/images/download_qrcode/Homepageca504-6fb61355bf.png`,
    cl: `${PUBLIC_CDN}/she_dist/images/download_qrcode/Homepagecl504-a1ce6c5d55.png`,
    de: `${PUBLIC_CDN}/she_dist/images/download_qrcode/Homepagede504-dfe2ff104a.png`,
    es: `${PUBLIC_CDN}/she_dist/images/download_qrcode/Homepagees504-a1604077fc.png`,
    eur: `${PUBLIC_CDN}/she_dist/images/download_qrcode/Homepageeur504-6d205a3c94.png`,
    fr: `${PUBLIC_CDN}/she_dist/images/download_qrcode/Homepagefr504-cd97e73666.png`,
    hk: `${PUBLIC_CDN}/she_dist/images/download_qrcode/Homepagehk504-dea29622fe.png`,
    il: `${PUBLIC_CDN}/she_dist/images/download_qrcode/Homepageil504-b6084d3671.png`,
    in: `${PUBLIC_CDN}/she_dist/images/download_qrcode/Homepagein504-29edb1b3b3.png`,
    it: `${PUBLIC_CDN}/she_dist/images/download_qrcode/Homepageit504-918513aa41.png`,
    ma: `${PUBLIC_CDN}/she_dist/images/download_qrcode/Homepagema504-dacf840e21.png`,
    mx: `${PUBLIC_CDN}/she_dist/images/download_qrcode/Homepagemx504-9c756150e4.png`,
    nl: `${PUBLIC_CDN}/she_dist/images/download_qrcode/Homepagenl504-fad37cdfc3.png`,
    nz: `${PUBLIC_CDN}/she_dist/images/download_qrcode/Homepagenz504-273480a565.png`,
    pl: `${PUBLIC_CDN}/she_dist/images/download_qrcode/Homepagepl504-c9b0d2ac88.png`,
    ru: `${PUBLIC_CDN}/she_dist/images/download_qrcode/Homepageru504-41bdb35382.png`,
    se: `${PUBLIC_CDN}/she_dist/images/download_qrcode/Homepagese504-8596f665af.png`,
    sg: `${PUBLIC_CDN}/she_dist/images/download_qrcode/Homepagesg504-93621028c4.png`,
    th: `${PUBLIC_CDN}/she_dist/images/download_qrcode/Homepageth504-0519398b22.png`,
    tw: `${PUBLIC_CDN}/she_dist/images/download_qrcode/Homepagetw504-b713e5a048.png`,
    uk: `${PUBLIC_CDN}/she_dist/images/download_qrcode/Homepageuk504-5e37f3caa9.png`,
    us: `${PUBLIC_CDN}/she_dist/images/download_qrcode/Homepageus504-5de3f76451.png`,
    vn: `${PUBLIC_CDN}/she_dist/images/download_qrcode/Homepagevn504-e1b737e594.png`,
    jp: `${PUBLIC_CDN}/she_dist/images/download_qrcode/Homepagejp504-61d8b1e677.png`,
    www: `${PUBLIC_CDN}/she_dist/images/download_qrcode/Homepagewww504-700b2544ce.png`,
    default: `${PUBLIC_CDN}/she_dist/images/download_qrcode/Homepagewww504-700b2544ce.png`,
    za: `${PUBLIC_CDN}/she_dist/images/download_qrcode/Homepageza504-0581e3c630.png`,
    roe: `${PUBLIC_CDN}/she_dist/images/download_qrcode/Homepageroe504-54d25c0176.png`,
    euqs: `${PUBLIC_CDN}/she_dist/images/download_qrcode/Homepageroe504-54d25c0176.png`,
  },
  rwImgUrls: {
    default: `${PUBLIC_CDN}/she_dist/rw_images/download_qrcode/HomepageRW505WWW-1603215d60.png`,
    rw: `${PUBLIC_CDN}/she_dist/rw_images/download_qrcode/HomepageRW505WWW-1603215d60.png`,
    rwus: `${PUBLIC_CDN}/she_dist/rw_images/download_qrcode/HomepageRW505US-1816a469d3.png`,
    rwuk: `${PUBLIC_CDN}/she_dist/rw_images/download_qrcode/HomepageRW505UK-13a9d2d347.png`,
    rwmx: `${PUBLIC_CDN}/she_dist/rw_images/download_qrcode/HomepageRW505MX-0074e4e8ab.png`,
    rwit: `${PUBLIC_CDN}/she_dist/rw_images/download_qrcode/HomepageRW505IT-e25059f1b9.png`,
    rwin: `${PUBLIC_CDN}/she_dist/rw_images/download_qrcode/HomepageRW505IN-4cffd725f9.png`,
    rwfr: `${PUBLIC_CDN}/she_dist/rw_images/download_qrcode/HomepageRW505FR-d4ae88d6a8.png`,
    rwes: `${PUBLIC_CDN}/she_dist/rw_images/download_qrcode/HomepageRW505ES-57e8000c19.png`,
    rwde: `${PUBLIC_CDN}/she_dist/rw_images/download_qrcode/HomepageRW505DE-9592f5e855.png`,
    rwca: `${PUBLIC_CDN}/she_dist/rw_images/download_qrcode/HomepageRW505CA-c47b7a2e81.png`,
    rwau: `${PUBLIC_CDN}/she_dist/rw_images/download_qrcode/HomepageRW505AU-3307326399.png`,
    rwar: `${PUBLIC_CDN}/she_dist/rw_images/download_qrcode/HomepageRW505AR-15449b9b6d.png`,
  },
  sheinUrls: {
    ru: 'https://apps.apple.com/ru/app/shein-fashion-shopping-online/id878577184',
    de: 'https://apps.apple.com/de/app/shein-shopping-und-fashion/id878577184',
    it: 'https://apps.apple.com/it/app/shein-moda-e-shopping/id878577184',
    fr: 'https://apps.apple.com/fr/app/shein-vente-privee-mode-femme/id878577184',
    th: 'https://apps.apple.com/th/app/shein-fashion-shopping-online/id878577184',
    tw: 'https://apps.apple.com/tw/app/shein-fashion-shopping-online/id878577184',
    hk: 'https://apps.apple.com/hk/app/shein-fashion-shopping-online/id878577184?l=en',
    au: 'https://apps.apple.com/au/app/shein-fashion-shopping-online/id878577184',
    uk: 'https://apps.apple.com/gb/app/shein-fashion-shopping-online/id878577184',
    mx: 'https://apps.apple.com/mx/app/shein-fashion-online-shopping/id878577184',
    jp: 'https://apps.apple.com/jp/app/y-ub-streetwear-fashion-shopping/id878577184',
    default:
      'https://itunes.apple.com/us/app/yub-streetwear-fashion-shopping/id878577184?mt=8',
  },
  rwUrls: {
    rwfr: 'https://itunes.apple.com/fr/app/romwe-shopping-fashion-clothing/id1080248000?mt=8',
    rwes: 'https://itunes.apple.com/es/app/romwe-shopping-fashion-clothing/id1080248000?mt=8',
    rwde: 'https://itunes.apple.com/de/app/romwe-shopping-fashion-clothing/id1080248000?mt=8',
    default:
      'https://itunes.apple.com/us/app/romwe-shopping-fashion-clothing/id1080248000?mt=8',
  },
  sheinUrlsAndroid: {
    jp: 'https://play.google.com/store/apps/details?id=com.zzkko&hl=ja',
    default: 'https://play.google.com/store/apps/details?id=com.zzkko&hl=en',
  },
  rwUrlsAndroid: {
    rwfr: 'https://play.google.com/store/apps/details?id=com.romwe&hl=fr',
    rwes: 'https://play.google.com/store/apps/details?id=com.romwe&hl=es',
    rwde: 'https://play.google.com/store/apps/details?id=com.romwe&hl=de',
    default: 'https://play.google.com/store/apps/details?id=com.romwe',
  },
}
